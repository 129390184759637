<template>
  <CardWrapper
    min-height="auto"
    class="pa-3"
    max-width="175"
    shadow="hover"
  >
    <VRow
      align="center"
      dense
    >
      <VCol
        v-if="showPrepend"
        cols="auto"
        align-self="center"
      >
        <slot name="prepend">
          <IconPlaceholder
            class="d-flex"
            :avatar-size="avatarSize"
            :icon-size="iconSize"
            :icon="prependIcon"
            :icon-color="prependIconColor"
          />
        </slot>
      </VCol>
      <VCol>
        <VRow dense>
          <VCol cols="12">
            <VSheet
              color="tt-light-mono-8"
              width="80"
              class="rounded-lg"
              height="4"
            />
          </VCol>
          <VCol cols="12">
            <VSheet
              color="tt-light-mono-8"
              width="50"
              class="rounded-lg"
              height="4"
            />
          </VCol>
        </VRow>
      </VCol>
      <VCol
        v-if="showAppend"
        cols="auto"
      >
        <slot name="append">
          <IconPlaceholder
            class="d-flex"
            :avatar-size="avatarSize"
            :icon-size="iconSize"
            :icon="prependIcon"
            :icon-color="prependIconColor"
          />
        </slot>
      </VCol>
    </VRow>
  </CardWrapper>
</template>

<script>
import CardWrapper from '@/components/shared/CardWrapper.vue';
import IconPlaceholder from '@/components/placeholders/IconPlaceholder.vue';

export default {
  name: 'ListItemSkeleton',
  components: { IconPlaceholder, CardWrapper },
  props: {
    prependIconColor: {
      type: [String, Boolean],
      default: false,
      required: false,
    },
    prependIcon: {
      type: [String, Boolean],
      default: false,
      required: false,
    },
    appendIconColor: {
      type: [String, Boolean],
      default: false,
      required: false,
    },
    appendIcon: {
      type: [String, Boolean],
      default: false,
      required: false,
    },
    avatarSize: {
      type: [Number, String],
      default: 18,
      required: false,
    },
    iconSize: {
      type: [Number, String],
      default: 8,
      required: false,
    },
  },
  computed: {
    showPrepend() {
      return this.$slots.prepend || this.prependIcon;
    },
    showAppend() {
      return this.$slots.append || this.appendIcon;
    },
  },
};
</script>

<style scoped>

</style>
