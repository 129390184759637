export const CONTENT_TYPES = {
  HTML: 'html',
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document',
  YES_NO: 'yesNo',
  SELECT_ONE: 'selectOne',
  SELECT_MANY: 'selectMany',
  VOTE: 'vote',
  RATE: 'rate',
  CONTACT: 'contact',
  MAP: 'map',
  ENTER_STRING: 'enterString',
  ONE_WORD: 'oneWord',
  ADD_PHOTO: 'addPhoto',
  QR_CODE: 'qrCode',
  OFFER: 'offer',
};

export const CONTENT_TYPE_ICONS = {
  [CONTENT_TYPES.HTML]: 'fal fa-code',
  [CONTENT_TYPES.IMAGE]: 'fal fa-file-image',
  [CONTENT_TYPES.VIDEO]: 'fal fa-video',
  [CONTENT_TYPES.DOCUMENT]: 'fal fa-file-alt',
  [CONTENT_TYPES.YES_NO]: 'fal fa-chart-pie-alt',
  [CONTENT_TYPES.SELECT_ONE]: 'fal fa-clipboard-check',
  [CONTENT_TYPES.SELECT_MANY]: 'fal fa-clipboard-list-check',
  [CONTENT_TYPES.VOTE]: 'fal fa-chart-bar',
  [CONTENT_TYPES.RATE]: 'fal fa-smile',
  [CONTENT_TYPES.CONTACT]: 'fal fa-user-tie',
  [CONTENT_TYPES.MAP]: 'fal fa-map-marker-alt',
  [CONTENT_TYPES.ENTER_STRING]: 'fal fa-keyboard',
  [CONTENT_TYPES.ONE_WORD]: 'fal fa-comment',
  [CONTENT_TYPES.ADD_PHOTO]: 'fal fa-camera',
  [CONTENT_TYPES.QR_CODE]: 'fal fa-qrcode',
  [CONTENT_TYPES.OFFER]: 'fal fa-user-check',
};
export const CONTENT_TYPE_ICON_COLOR = 'tt-light-purple';
