<template>
  <OTable
    ref="dragable"
    hide-default-footer
    :headers="headers"
    :items="items"
    :search="search"
    :item-class="generateItemClass"
    :custom-filter="handleFilter"
    v-on="$listeners"
  >
    <template #top>
      <VContainer class="px-0">
        <VRow align="end">
          <VCol cols="10">
            <TTTextField
              v-model="search"
              large
              data-test-label="level-list-search"
              label="Поиск"
              placeholder="Поиск по названию"
              clearable
              append-icon="fal fa-search"
              data-test="track-input-search"
            />
          </VCol>
          <VCol
            v-if="showCreateButton"
            cols="2"
          >
            <TTBtn
              height="44"
              block
              data-test-label="level-list-add-button"
              @click="handleClickOnAdd"
            >
              Добавить
            </TTBtn>
          </VCol>
        </VRow>
      </VContainer>
    </template>
    <!--    eslint-disable-next-line vue/no-unused-vars-->
    <template #item.drag="{item}">
      <VIcon>
        fal fa-grip-lines
      </VIcon>
    </template>
    <template #item.n="{item}">
      <div data-test="number">
        {{ item.n }}
      </div>
    </template>
    <template #item.name="{item}">
      <div data-test="name">
        {{ item.name }}
      </div>
    </template>
    <template #item.maleIcons>
      <VImg
        data-test="image-male"
        width="80"
        :src="require(`@/assets/images/1.png`)"
      />
    </template>
    <template #item.femaleIcons>
      <VImg
        data-test="image-female"
        width="80"
        :src="require(`@/assets/images/1.png`)"
      />
    </template>
    <template #item.open_percent="{item}">
      <div data-test="open-percent">
        {{ item.open_percent }}
      </div>
    </template>
    <template #item.bonus="{item}">
      <VRow
        align="center"
        justify="space-between"
      >
        <VCol
          cols="auto"
          data-test="bonus"
        >
          {{ item.bonus }}
        </VCol>
        <VCol cols="auto">
          <MenuWrapper :menu-list="getRowActionList(item)">
            <template #activator="{attrs,on}">
              <!--
              TODO FIX По неизвестной причине если не указать ripple="true" криво работают события, а именно
              при ripple="false" анимация клика срабатывает даже на самой карточке не смотря на указанный
              @click.native.prevent, если указать ripple="true", то все работает как ожидается
               -->
              <TTBtn
                small
                v-bind="attrs"
                min-width="28"
                :ripple="true"
                text
                data-test-label="button-menu"
                v-on="on"
                @click.native.prevent
              >
                <VIcon>
                  fal fa-ellipsis-h
                </VIcon>
              </TTBtn>
            </template>
          </MenuWrapper>
        </VCol>
      </VRow>
    </template>
  </OTable>
</template>

<script>
import OTable from '@/components/shared/OTable.vue';
import Sortable from 'sortablejs';
import MenuWrapper from '@/components/shared/MenuWrapper.vue';

export default {
  name: 'OLevelList',
  components: {
    MenuWrapper,
    OTable,
  },
  props: {
    showCreateButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: '',
          value: 'drag',
          sortable: false,
          align: 'center',
          cellClass: 'sortHandle',
          width: 40,
        },
        {
          text: '№',
          value: 'n',
          sortable: false,
          width: 60,
          align: 'center',
        },
        {
          text: 'Название',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Муж.',
          value: 'maleIcons',
          sortable: false,
          width: 128,
        },
        {
          text: 'Жен.',
          value: 'femaleIcons',
          sortable: false,
          width: 128,
        },
        {
          text: '% открытия',
          value: 'open_percent',
          sortable: false,
          width: 128,
        },
        {
          text: 'Бонусы',
          value: 'bonus',
          sortable: false,
          width: 128,
        },
      ],
      items: [
        {
          drag: true,
          n: 1,
          name: 'Название',
          maleIcons: '',
          femaleIcons: '',
          open_percent: 100,
          bonus: 100,
        },
        {
          drag: true,
          n: 2,
          name: 'Название 2',
          maleIcons: '',
          femaleIcons: '',
          open_percent: 100,
          bonus: 100,
        }],
    };
  },
  mounted() {
    this.initSortableJs();
  },
  methods: {
    // Поиск исключително по названию, если будет поиск по контенту, то просто убрать эту функцию
    handleFilter(value, search, item) {
      return value != null
        && search != null
        && typeof value !== 'boolean'
        && item.name.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
    },
    // eslint-disable-next-line no-unused-vars
    generateItemClass(item) {
      return 'cursor--pointer sortableRow';
    },
    initSortableJs() {
      Sortable.create(
        this.$refs.dragable.$el.getElementsByTagName('tbody')[0],
        {
          draggable: '.sortableRow',
          handle: '.sortHandle',
          animation: 150,
        },
      );
    },
    handleClickOnAdd() {
      this.$emit('click:add-button');
    },
    showEditForm(item) {
      /*
      * TODO DELETE  Обвязать открытие формы редактирования после создания самой формы
      */
      this.$root.$emit('showNotifier', {
        title: 'Это якобы открылась форма редактирования',
        msg: `В ней мы будем редактировать элемент с id:${item.n}, но пока еще не сделали формочку`,
        timeout: 6000,
        type: 'success',
      });
    },
    showDeleteModal() {
      /* TODO DELETE Сделать действие удаление с подтверждением,
       компонента есть, но возможно переделать ее на манер уведомления, куда будет просто передаваться заголовок, текст
       и колббеки на кнопки и ее можно будет вызывать из любого места, не вставляя прямо в компоненту,
       тем самым будем универсальная форма подтверждения удаления.
       P.S. Если не потребуется вставлять дополнительные слоты,
       в таком случае придется использовать локальную компоненту
      * */
      this.$root.$emit('showNotifier', {
        title: 'Здесь мы удаляем из таблички данные',
        msg: 'Формочка есть, но обвяжем ее уже когда будем удалять',
        timeout: 6000,
        type: 'error',
      });
    },
    getRowActionList(item) {
      return [
        {
          icon: 'fal fa-pen',
          color: '',
          text: 'Редактировать',
          dataTest: 'menu-action-edit',
          action: () => this.showEditForm(item),
        }, {
          icon: 'fal fa-trash-alt',
          color: 'tt-light-red',
          text: 'Удалить',
          dataTest: 'menu-action-delete',
          action: () => this.showDeleteModal(item),
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.sortHandle {
  cursor: move !important;
}
</style>
