<template>
  <VAvatar
    :size="avatarSize"
    :color="`${iconColor} pale`"
  >
    <VIcon
      :size="iconSize"
      :color="`${iconColor} base`"
    >
      {{ icon }}
    </VIcon>
  </VAvatar>
</template>

<script>
export default {
  name: 'IconPlaceholder',
  props: {
    iconColor: {
      type: String,
      default: 'tt-light-green',
      required: false,
    },
    icon: {
      type: String,
      default: 'fal fa-project-diagram',
      required: false,
    },
    avatarSize: {
      type: [Number, String],
      default: 36,
      required: false,
    },
    iconSize: {
      type: [Number, String],
      default: 24,
      required: false,
    },
  },
};
</script>

<style scoped>

</style>
