export const TASK_TYPE_TRANSLATION = {
  NONE: '',
  WATCH: 'Смотреть',
  READ: 'Прочитай',
  TAKE: 'Возьми',
  LOCATION: 'Локация',
  TEST: 'Тест',
  TASK: 'Задача',
  MEET: 'Встреча',
  QRCODE: 'QR-код',
  OTHER: 'Другое',
  BUG: 'Баг',
  POOL: 'Опрос',
};

export const TASK_TYPES = {
  NONE: 'none',
  WATCH: 'watch',
  READ: 'read',
  TAKE: 'take',
  LOCATION: 'location',
  TEST: 'test',
  TASK: 'task',
  MEET: 'meet',
  QRCODE: 'qrcode',
  OTHER: 'other',
  BUG: 'bug',
  POOL: 'pool',
};

export const TASK_TYPE_ICONS = {
  [TASK_TYPES.WATCH]: 'fal fa-play-circle',
  [TASK_TYPES.READ]: 'fal fa-book',
  [TASK_TYPES.TAKE]: 'fal fa-hand-point-down',
  [TASK_TYPES.LOCATION]: 'fal fa-map-marker-alt',
  [TASK_TYPES.TEST]: 'fal fa-tasks',
  [TASK_TYPES.TASK]: 'fal fa-glasses',
  [TASK_TYPES.MEET]: 'fal fa-handshake',
  [TASK_TYPES.QRCODE]: 'fal fa-qrcode',
  [TASK_TYPES.OTHER]: 'fal fa-box',
  [TASK_TYPES.BUG]: 'fal fa-bug',
  [TASK_TYPES.POOL]: 'fal fa-bell',
  [TASK_TYPES.NONE]: 'fal fa-box',
};

export const TASK_TYPE_ICON_COLOR = 'tt-light-blue';
