var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OTable',_vm._g({ref:"dragable",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-class":_vm.generateItemClass,"custom-filter":_vm.handleFilter},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('VContainer',{staticClass:"px-0"},[_c('VRow',{attrs:{"align":"end"}},[_c('VCol',{attrs:{"cols":"10"}},[_c('TTTextField',{attrs:{"large":"","data-test-label":"level-list-search","label":"Поиск","placeholder":"Поиск по названию","clearable":"","append-icon":"fal fa-search","data-test":"track-input-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.showCreateButton)?_c('VCol',{attrs:{"cols":"2"}},[_c('TTBtn',{attrs:{"height":"44","block":"","data-test-label":"level-list-add-button"},on:{"click":_vm.handleClickOnAdd}},[_vm._v(" Добавить ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.drag",fn:function(ref){
var item = ref.item;
return [_c('VIcon',[_vm._v(" fal fa-grip-lines ")])]}},{key:"item.n",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"number"}},[_vm._v(" "+_vm._s(item.n)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"name"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.maleIcons",fn:function(){return [_c('VImg',{attrs:{"data-test":"image-male","width":"80","src":require("@/assets/images/1.png")}})]},proxy:true},{key:"item.femaleIcons",fn:function(){return [_c('VImg',{attrs:{"data-test":"image-female","width":"80","src":require("@/assets/images/1.png")}})]},proxy:true},{key:"item.open_percent",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"open-percent"}},[_vm._v(" "+_vm._s(item.open_percent)+" ")])]}},{key:"item.bonus",fn:function(ref){
var item = ref.item;
return [_c('VRow',{attrs:{"align":"center","justify":"space-between"}},[_c('VCol',{attrs:{"cols":"auto","data-test":"bonus"}},[_vm._v(" "+_vm._s(item.bonus)+" ")]),_c('VCol',{attrs:{"cols":"auto"}},[_c('MenuWrapper',{attrs:{"menu-list":_vm.getRowActionList(item)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('TTBtn',_vm._g(_vm._b({attrs:{"small":"","min-width":"28","ripple":true,"text":"","data-test-label":"button-menu"},nativeOn:{"click":function($event){$event.preventDefault();}}},'TTBtn',attrs,false),on),[_c('VIcon',[_vm._v(" fal fa-ellipsis-h ")])],1)]}}],null,true)})],1)],1)]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }